export const uk = {
  translation: {
    reportSave: 'Зберегти звіт',
    startDateVacancies: 'Відкриття вакансії',
    endDateVacancies: 'Закриття вакансії',
    toastPasswordText: 'Пароль змінено!',
    toastInfoText: 'Інформацію змінено!',

    profileLabel: 'Профіль',
    profileMyLabel: 'Мій профіль',
    logoutLabel: 'Вийти',
    alreadyHaveAccountQuestion: 'Вже маєте аккаунт?',
    dontHaveAccountQuestion: 'Не маєте акаунту?',
    invitedText: 'Вас було запрошено до команди використовуючи:',
    welcomeText: 'Ласкаво просимо до',
    typographyActionTextSignup: 'Не маєте аккаунту?',
    typographyActionTextLogin: 'Маєте аккаунт?',
    typographyActionLinkSignup: 'Зареєструватися',
    typographyActionLinkLogin: 'Увійти',
    typographyActionTextResetPassword: 'Забули пароль?',
    typographyActionLinkResetPassword: 'Відновити пароль',
    resetPasswordSubtitle: 'Без проблем! Давайте створимо новий',
    sendResetPasswordLink: 'Відправити',
    sendResetPasswordNewLink: 'Відправити нове посилання',
    createNewPasswordTitle: 'Створити новий пароль',
    saveNewPasswordBtnText: 'Зберегти новий пароль',
    createNewPasswordSubtitle: 'Ви створюєте новий пароль для:',
    typographyActionPasswordDidntMatch: 'Два поля пароля не збігаються',
    expiredLinkTitle: 'Термін дії вашого посилання закінчився',
    expiredLinkText:
      'З міркувань безпеки термін дії посилання для скидання пароля закінчується через деякий час. Якщо вам все одно потрібно скинути пароль, ви можете надіслати новий електронний лист для скидання.',
    expiredInviteTitle: 'Термін дії посилання для запрошення закінчився.',
    expiredInviteDescription: 'Зв’яжіться з особою, яка надіслала вам посилання для запрошення.',

    userTableFullName: "Повне Iм'я",

    candidateHistoryAuthor: 'Автор',
    candidateHistoryOldStatus: 'Старий статус',
    candidateHistoryNewStatus: 'Новий статус',
    candidateHistoryDate: 'Дата запису',

    commentsTableTitle: 'Назва',
    commentsTableComment: 'Коментар',
    commentsTableDate: 'Дата',

    nonTrimmedField: 'Поле не повинно містити пробіли',
    nonValidEmail: 'Введіть дійсну електронну пошту',
    passwordsDontMatch: 'Два поля пароля не збігаються',
    requiredField: "Це поле є обов'язковим",
    requiredButton: 'Потрібно обрати одну з кнопок',
    greaterThanField: 'Число має бути більше 0',
    integerField: 'Число має бути цілим',
    passwordHint:
      'Пароль має містити: велику літеру, маленьку літеру, число та спеціальний знак. Мінімальна кількість символів - 8',
    securityTab: 'Безпека',
    historyTab: 'Історія',

    createUserTitle: 'Створення користувача',
    createCandidateTitle: 'Створити кандидата',
    createCandidateSuccessMsg: 'Кандидата успішно створено',
    editCandidateSuccessMsg: 'Кандидата успішно оновлено',
    createCandidateErrorMsg: 'Щось пішло не так',
    editUserTitle: 'Редагувати користувача',
    editCandidateTitle: 'Редагувати кандидата',
    basicInfoTitle: 'Основна інформація',
    locationTitle: 'Місце розташування',
    contactInfoTitle: 'Контактна інформація',
    additionalInformation: 'Додаткова інформація',
    financialInfoTitle: 'Фінансова інформація',
    hrInfoTitle: 'Інформація для HR',
    signUpTitle: 'Реєстрація',
    signInTitle: 'Увійти',
    resetPasswordTitle: 'Скинути пароль',
    resetPasswordOneTimeLinkTitle: 'Посилання для зміни пароля надіслано на вашу електронну пошту',
    resetPasswordSuccess: 'Пароль успішно змінено',

    agreementsText: 'Натискаючи `Реєстрація` Ви автоматично підтверджуєте нашу',
    privacyPolicy: 'Політику приватності',

    firstNameLabel: "Ім'я",
    lastNameLabel: 'Прізвище',
    firstNameLatinField: "Ім'я (латиниця)",
    lastNameLatinField: 'Прізвище (латиниця)',
    firstNameLocalField: "Ім'я (локальне)",
    lastNameLocalField: 'Прізвище (локальне)',
    birthdayField: 'День народження',
    emailField: 'Пошта',
    emailLabel: 'Email',
    omiEmailField: 'Пошта OmiSoft',
    mobilePhoneField: 'Телефон',
    telegramField: 'Телеграм',
    passwordField: 'Пароль',
    oldPasswordField: 'Старий пароль',
    newPasswordField: 'Новий пароль',
    confirmPasswordField: 'Підтвердіть пароль ще раз',
    passwordLabel: 'Створіть пароль',
    repeatPasswordLabel: 'Повторіть пароль',
    enterPasswordLabel: 'Введіть пароль',
    reasonForDismissal: 'Причина звільнення',
    specializationsField: 'Спеціалізації',
    tagsField: 'Теги кандидата',
    developerGradeField: 'Ступінь',
    techStackField: 'Технологічний стек',
    englishLevelField: 'Рівень англійської',
    englishField: 'Англійська',
    roleField: 'Роль',
    candidateStatusField: 'Статус кандидата',
    openVacanciesField: 'Відкриті вакансії',
    chosenVacancy: 'Вакансія',
    workingHoursField: 'Робочі години',
    cvLinkField: 'Посилання на резюме',
    otherLinksField: 'Інші посилання',
    countryField: 'Країна',
    cityField: 'Місто',
    novaPoshtaAddressField: 'Адреса Нової Пошти',
    skypeField: 'Skype',
    gitHubField: 'GitHub користувача',
    gitLabField: 'GitLab користувача',
    linkedInField: 'LinkedIn',
    upworkLinkField: 'UpWork посилання',
    fopField: 'Ви ФОП? (Приватний підприємець)',
    payoneerEmailField: 'Електронна пошта Payoneer',
    monobankCardField: 'Картка Монобанк',
    privatBankCardField: 'Картка ПриватБанк',
    ipnField: 'ІПН (Індивідуальний податковий номер)',
    dateOfStartingField: 'Дата початку',
    dateOfBirthday: 'День народження',
    channelField: 'Канали',
    minSalaryField: 'Зарплата (Мін)',
    maxSalaryField: 'Зарплата (Макс)',
    minRateField: 'Тариф (Мін)',
    maxRateField: 'Тариф (Макс)',
    employmentTypeField: 'Тип співпраці',

    saveButton: 'Зберегти',
    addButton: 'Додати',
    addNewButton: 'Додати новий',
    editButton: 'Редагувати',
    editProfileButton: 'Редагувати профіль',
    avatarSelectMenu: 'Ви можете вибрати аватар',
    avatarSelectButton: 'Завантажити аватар',
    deleteButton: 'Видалити',
    cancelButton: 'Відмінити',
    applyButton: 'Застосувати',
    filterButton: 'Фільтри',
    hireButton: 'Найняти',
    rejectButton: 'Відмовити',
    updateButton: 'Оновити',
    okButton: 'Зрозуміло',
    generatePasswordButton: 'Сгенерувати новий пароль',
    createUserButton: 'Створити користувача',
    createCandidateButton: 'Створити кандидата',
    editCandidateButton: 'Зберегти зміни',
    updateUserButton: 'Оновити користувача',
    updateCandidateButton: 'Оновити',
    savePasswordButton: 'Зберегти пароль',
    submitBtn: 'Далі',
    submitFridayStatusTitle: 'Ти затрекав тиждень?',
    submitFridayStatus: 'Так, я затрекав увесь тиждень',
    denyFridayStatus: 'Ні, пізніше',
    searchUsers: 'Пошук користувачів',
    assignedToLabel: 'Назначено на',
    goTocomments: 'Перейти до коментарів',
    isFired: 'Звільнений',
    candidatesAttribute: 'Додати мітку',
    hrInterview: "HR інтерв'ю",
    checkedEnglishLevel: 'Перевірені знання англ. мови',
    needPing: ' Повторний пінг',
    testTaskIsDone: 'Виконане тестове завдання',
    partTime: 'Неповний робочий день',
    fullTime: 'Повний робочий день',
    raisingStar: 'Перспективний кандидат',
    traineeGrade: 'Trainee',
    juniorGrade: 'Junior',
    middleGrade: 'Middle',
    seniorGrade: 'Senior',
    partTimeLabel: 'Неповний робочий день',
    fullTimeLabel: 'Повний робочий день',

    createVacationSuccess: 'Вакансію успішно створено',
    pendingStatus: 'Очікується',

    labelYes: 'Так',
    labelNo: 'Ні',
    labelFire: 'Звільнити',
    labelCancel: 'Відмінити',
    labelStartDate: 'Дата початку',
    labelYear: 'Виберіть рік',
    labelDate: 'Оберіть дату',

    teamSection: 'Команда',
    candidatesSection: 'Кандидати',
    RecruitmentHRSection: 'Рекрутинг та HR',
    remindersSection: 'Нагадування',
    vacanciesSection: 'Вакансії',
    hrStatisticsSection: 'Статистика',
    clientsSection: 'Клієнти',
    withoutTags: 'Без тегів',

    loadMore: 'Підгрузити Ще',

    filterByStatus: 'Статус кандидата',
    filterByName: "Повне Ім'я",
    filterBySpecialization: 'Спеціалізація',
    filterByGrade: 'За рангом',
    filterByVacancy: 'Вакансія',
    filterByVacancyStatus: 'Статус вакансії',
    filterByCandidateAttribute: 'Атрибут',
    filterByRefusalReason: 'Причина відмови',
    allStatusesFilter: 'Усі',
    filterAllCandidates: 'Усі кандидати',

    removeFilter: 'Видалити фільтр',
    removeVacancyCandidate: 'Видалити вакансію',

    noDataWarning: 'Немає даних',
    noVacancySelected: 'Виберіть вакансію або спеціалізацію',
    noCandidates: 'Кандидатів не знайдено',

    allStatuses: 'Усі статуси',
    newStatus: 'Нова заявка',
    preScreeningStatus: 'Попередній скринінг',
    onPendingStatus: 'На розгляді лідів',
    hrInterviewStatus: `HR інтерв'ю`,
    suitableStatus: 'Підходить',
    approvedByLeadStatus: 'Затверджено лідом',
    testStatus: 'Тестове завдання',
    techInterviewStatus: `Технічне інтерв'ю`,
    preSuitableStatus: 'Попередньо підходить',
    customerInterviewStatus: `Інтерв'ю з клієнтом`,
    notSuitableStatus: 'Не підходить',
    sentOfferStatus: 'Надіслали Offer',
    acceptedOfferStatus: 'Offer прийнято',
    onboardingStatus: 'Онбоардінг',
    inTeamStatus: 'В команді',
    blacklistStatus: 'Чорний список',
    needPingStatus: 'Повторний пінг',
    contractorStatus: 'Контрактер',
    doneStatus: 'Готово',

    hrInterviewAttribute: 'HR Співбесіда',
    englishLevelAttribute: 'Перевірка англійської мови',
    needPingAttribute: 'Повторний пінг',
    testTaskAttribute: 'Тестове завдання виконане',
    partTimeAttribute: 'Неповна зайнятість',
    fullTimeAttribute: 'Повний робочий день',
    starAttribute: 'Перспективний',

    weekTimeTraked: 'Будь ласка, підтвердіть робочі години на цьому тижні',
    docsNotFound: 'Документацій не знайдено',

    editUserSuccessMsg: 'Користувача успішно оновлено',
    changeUserEmailErrorMsg: 'Користувач із цією електронною адресою вже існує',
    cantSaveReportMsg: 'Немає даних. Неможливо зберегти звіт',
    specializationCreateSuccessMsg: 'Спеціалізацію успішно створено',
    specializationUpdateSuccessMsg: 'Спеціалізацію успішно оновлено',
    specializationDeleteSuccessMsg: 'Спеціалізацію успішно видалено',

    userTitle: 'Користувач',

    deleteModalSubmit: 'Видалити',
    deleteModalDeny: 'Скасувати',

    JanMonth: 'Січ',
    FebMonth: 'Лют',
    MarMonth: 'Берез',
    AprMonth: 'Квіт',
    MayMonth: 'Трав',
    JunMonth: 'Черв',
    JulMonth: 'Лип',
    AugMonth: 'Серп',
    SepMonth: 'Верес',
    OctMonth: 'Жовт',
    NovMonth: 'Листоп',
    DecMonth: 'Груд',

    goBackBtn: 'Повернутися назад',
    goBackBtnReset: 'Повернутися до',
    userDetailsTab: 'Деталі',
    userCommentsTab: 'Коментарі',
    userFinancialInfoTab: 'Фінансова інформація',
    userDocsAndLinksTab: 'Документи & Лінки',
    createCommentButton: 'Створити коментар',
    editCommentButton: 'Редагувати коментар',

    candidateReviewUserComment: 'Відгук про кандидата',
    englishTestUserComment: 'Перевірка англійської',
    testTaskUserComment: 'Тестове завдання',
    interviewReviewUserComment: 'Відгук про співбесіду',
    refusalReasonUserComment: 'Причина відмови',
    candidateHiredUserComment: 'Кандидата найнято',
    otherUserComment: 'Інше',

    noExperienceRefusalReason: 'Немає комерційного досвіду',
    lowEnglishRefusalReason: 'Низький рівень володіння англійською',
    requiresHighSalaryRefusalReason: 'Потребує завелику ЗП',
    noContactRefusalReason: "Перестав виходити на зв'язок",
    workInOfficeRefusalReason: 'Бажає працювати в офісі',
    interviewFailedRefusalReason: 'Не пройшов співбесіду',
    offerDeclinedRefusalReason: 'Відмовився від оффера або прийняв інший',
    toxicRefusalReason: 'Токсична людина чи неадекватна',
    overratedOwnCapabilitiesReason: 'Переоцінює свої можливості',

    userCommentEditSuccess: 'Коментар успішно оновлено',
    userCommentCreateSuccess: 'Коментар додано',
    commentTitleLabel: 'Заголовок',
    refusalReasonLabel: 'Причина відмови',
    commentDescriptionLabel: 'Опис коментаря',
    reminderDescriptionLabel: 'Напишіть тут опис до сповіщення',
    anonymouslyStatusLabel: 'Анонімно',
    userCommentDeleteTitle: 'Ви впевнені, що хочете видалити коментар?',
    userCommentDeleteSuccess: 'Коментар успішно видалено',
    reminderLabel: 'Нагадування',

    userReviewsTab: 'Відгуки',
    anonymousAuthor: 'Анонімний автор',
    suggestedToStartDoingTitle: 'Що колезі варто почати робити',
    suggestedToStopDoingTitle: 'Що колезі варто перестати робити',
    suggestedToContinueDoingTitle: 'Що колега робить добре і має продовжувати',
    userReviewDeleteTitle: 'Ви впевнені, що хочете видалити відгук?',
    createReviewButton: 'Створити відгук',
    editReviewButton: 'Редагувати відгук',
    reviewCreateSuccess: 'Відгук створено',
    reviewEditSuccess: 'Відгук редаговано',
    reviewDeleteSuccess: 'Відгук видалено',

    userRemindersTab: 'Нагадування',
    createReminderLabel: 'Створити нагадування',
    createReminderText: 'Введіть дату та опис нагадування',
    editReminderLabel: 'Редагувати нагадування',
    reminderAuthor: 'Автор',
    reminderUser: 'Користувач',
    reminderDate: 'Дата',
    reminderDescription: 'Опис',
    createReminderSuccess: 'Нагадування створено',
    editReminderSuccess: 'Нагадування змінено',
    deleteReminderSuccess: 'Нагадування успішно видалено',
    deleteReminderTitle: 'Ви впевнені, що хочете видалити нагадування?',
    goToReminders: 'Перейти до нагадувань кандидата',
    noReminders: 'Немає нагадувань',

    candidateChennel: 'Джерела кандидатів',
    vacancyStatus: 'Статус вакансії',
    vacancyTitle: 'Вакансія',
    vacancySpecialization: 'Спеціалізація',
    additionRole: 'Додаткові ролі',
    vacancyGrade: 'Ступінь',
    vacancyDescription: 'Про вакансію',
    vacancyStartDate: 'Дата початку',
    vacancyEndDate: 'Дата завершення',
    vacancyOpen: 'Відкрита',
    vacancyEdit: 'В редагуванні',
    vacancyTemplate: 'Шаблон',
    vacancyCancelled: 'Скасована',
    vacancyCloseSuccessful: 'Закрита успішно',
    vacancyCloseSuccessfulSubtitle:
      'Ви впевнені, що хочете закрити цю вакансію? Ця вакансія буде переведена в статус успішно закритої.',
    vacancyCloseUnSuccessful: 'Закрита неуспішно',
    vacancyCloseUnSuccessfulSubtitle:
      'Ви впевнені, що хочете закрити цю вакансію? Ця вакансія буде переведена в статус закритої неуспішно.',
    vacancyArchive: 'Архів',
    createVacancy: 'Створити вакансію',
    createVacancyFromTemplate: 'Створити вакансію з шаблону',
    editVacancy: 'Редагувати вакансію',
    editStatusVacancy: 'Змінити статус вакансії',
    openTheVacancy: 'Ви точно хочете відкрити вакансію?',
    closeTheVacancy: 'Ви точно хочете закрити вакансію?',
    confirmOpenVacancy: 'Відкрити',
    confirmCloseVacancy: 'Закрити',
    vacancyEditSuccess: 'Вакансія була успішно змінена!',
    vacancyStatusSuccess: 'Статус вакансії був успішно змінений!',
    backToVacancies: 'Повернутись до вакансій',
    allStatusesVacancies: 'Всі статуси',
    deleteVacancy: 'Видалити вакансію',
    deleteVacancyConfirm: 'Ви точно хочете видалити цю вакансію?',
    deleteVacancySuccess: 'Вакансія була успішно видалена!',
    chooseTemplate: 'Виберіть шаблон',
    vacancyDetailsTab: 'Деталі',
    vacancyCandidatesTab: 'Кандидати',
    candidatesTab: 'Дошка',
    candidatesListTab: 'Список',
    noVacancyCandidates: 'Кандидати без вакансії',
    vacancyFunnelOfCandidatesTab: 'Статистика',
    vacancyCandidatesNoData: 'По цій вакансії ще немає жодного кандидата.',
    noCandidateHistory: 'По цьому кандидату ще немає жодної історії',
    goToVacancyButton: 'Перейдіть до вакансії',

    createChannelTitle: 'Додати канал',
    editChannelTitle: 'Редагувати канал',
    channelSelectTitle: 'Виберіть канал',
    deleteChannelConfirm: 'Виберіть спеціалізацію який ви хочете видалити.',

    createSpecializationTitle: 'Додати спеціалізацію',
    createSpecializationButton: 'Додати',
    editSpecializationTitle: 'Редагувати спеціалізацію',
    specializationSelectTitle: 'Виберіть спеціалізацію',
    editSpecializationButton: 'Редагувати',
    deleteSpecializationButton: 'Видалити',
    deleteSpecializationConfirm: 'Виберіть спеціалізацію яку ви хочете видалити.',
    specializationName: 'Назва',
    newSpecializationName: 'Нова назва',

    statisticsVacanciesTitle: 'Статистика закриття вакансій',
    closedSuccessfullyField: 'Закриті успішно',
    closedUnSuccessfullyField: 'Закриті не успішно',
    hiringAndFiringStatistics: 'Статистика звільнень та наймів',
    hiredEmpoleesLabel: 'Найняті працівники',
    firedEmpoleesLabel: 'Звільнені працівники',
    dateOfHiring: 'Дата найму',
    dateOfDismissal: 'Дата звільнення',
    statisticsFunnelOfCandidatesTitle: 'Воронка кандидатів',
    vacancyStatusLabel: 'Статус вакансії',
    vacancyLabel: 'Виберіть вакансію',
    suitableStatusCandidate: 'Підходить',
    checkingEnglishStatus: 'Перевірка англійської',
    underLeadsConsideration: 'На розгляді лідом',

    hireCandidate: 'Найняти кандидата',
    canditateEditStatus: 'Редагувати статус',
    fireAnEmployee: 'Звільнити працівника',
    roleTitle: 'Виберіть роль',
    fireEmployee: 'Ви точно хочете звільнити працівника?',
    firedEmployeeSuccessMsg: 'Працівника було звільнено',
    hiredCandidateSuccessMsg: 'Кандидат був прийнятий на роботу',
    deleteEmployeeConfirmation: 'Ви впевнені, що хочете видалити кандидата зі статистики?',
    deleteEmployeeSuccess: 'Кандидат успішно видаленний зі статистики!',

    changeCandidateStatusSuccessMsg: 'Статус кандидата успішно оновлено',
    changeCandidateStatusErrorMsg: 'Не вдалося оновити статус кандидата',
    usersDoesNotExist: 'Користувачів не знайдено',

    documentNameCell: 'Назва документа',
    documentTypeCell: 'Тип документа',
    documentUploadedCell: 'Завантажено',
    documentCreateSuccessMsg: 'Документ успішно збережений ',
    documentUpdateSuccessMsg: 'Документ успішно оновлений ',
    documentUpdateFailureMsg: 'Помилка збереження документа',
    documentCreateFailureMsg: 'Помилка оновлення документа',
    deleteDocumentConfirm: 'Ви точно хочете видалити цей документ?',
    deleteDocumentSuccess: 'Документ успішно видалено',
    deleteDocumentFailure: 'Помилка видалення документа',
    documentMenuFileLabel: 'Додати файл',
    documentMenuLinkLabel: 'Додати посилання',
    documentModalName: 'Назва',
    documentModalDescription: 'Опис',
    documentModalType: 'Тип',
    documentModalVisibility: 'Видимість',
    documentModalLink: 'Посилання',
    documentModalAdd: 'Додати документ',
    documentModalEdit: 'Редагувати документ',
    documentModalAddFile: 'Додати файл',
    documentModalAddLink: 'Додати посилання',
    documentModalEditFile: 'Редагувати файл',
    documentModalEditLink: 'Редагувати посилання',
    documentsDoesntExist: 'Не має дуступних документів',
    documentDescriptionCell: 'Опис',
    editUserVacancyInfo: "Не можна змінювати вакансію кандидату зі статусом 'не підходить' чи 'готово'",

    teamProfileLinkText: 'Профіль',
    teamInvitePeopleText: 'Запросити людей',
    teamSecurityAndPermissionsText: 'Безпека і Доступи',
    userSettingsText: 'Мої налаштування',
    userHelpLinkText: 'Допомога',
    profilePageTitle: 'Профіль',
    addTeamText: 'Додати нову команду',
    teamList: 'Список команд',

    welcomeTeamCreationTitle: 'Привіт',
    welcomeTeamCreationSubtitle: 'Давай створимо твою першу команду',
    teamNameInputLabel: 'Назва команди',
    teamNameFieldTitle: 'Придумай назву для своєї команди',
    memberFullname: "Повне ім'я (латиною)",
    membersEmailsInputLabel: 'Електронна пошта учасників',
    membersEmailsInputHelperText: "Натисни 'ENTER' щоб додати",
    membersEmailsFieldTitle: 'Запроси людей до своєї команди',
    searchEngineButtonText: 'Пошукова система (Google, Bing, і т.д.)',
    socialMediaButtonText: 'Соціальні мережі',
    referralButtonText: 'Реферальна програма',
    blogButtonText: 'Блог або публікація',
    otherChannelLabel: 'Інший канал',
    sourceFieldTitle: 'Як ви дізналися про Omi Team?',
    justMeButtonText: 'Тільки я',
    teamSizeFieldTitle: 'Обери, будь ласка, розмір твоєї команди:',
    nextStepButtonText: 'Наступний крок',
    backButtonText: 'Назад',
    createTeamFinishTitle: 'Вітаю!',
    createTeamFinishSubtitle: 'Ти створив свою першу команду',
    createTeamButtonText: 'Продовжити в Omi Team',
    createTeamSuccessNotification: 'Твоя команда успішно створена!',
    nonUniqueEmailError: 'Ця електронна пошта вже додана',

    teamOwnerRole: 'Власник',
    teamAdminRole: 'Адмін',
    userRole: 'Учасник',
    userRoleSelectText: 'Оберіть роль користувача',
    sendInviteBtnText: 'Запросити',
    membersInvitedSuccess: 'Користувачів успішно запрошено!',
    cancelInviteSuccess: 'Запрошення для користувача успішно скасоване!',
    emptyEmailsError: 'Додайте мейл користувача перед відправленням запрошення!',
    emptyFullnameError: "Додайте ім'я користувача перед відправленням запрошення!",
    logoutModalTitle: 'Ви впевнені що хочете вийти',

    renameTeamTitle: 'Перейменуй свою команду',
    teamSettingsTitle: 'Налаштування команди',
    teamRenameText: 'Перейменувати команду',
    teamDeleteText: 'Видалити команду',
    renameTeamSuccess: 'Команду успішно перейменовано',
    deleteTeamSuccess: 'Команду успішно видалено',
    deleteModalTitle: 'Видалення команди',
    deleteModalSubtitleQuestion: 'Ви впевнені що хочете видалити свою команду',
    deleteModalSubtitleExplaining:
      "Якщо Ви видалите її, то всі дані буде втрачено. Для підтвердження дії введіть своє ім'я і підтвердіть.",
    approveDeleteLabel: 'Підтвердити видалення',
    userNameInputLabel: "Впишіть своє ім'я",
    nonCorrectUsernameError: "Подане ім'я не співпадає з вашим іменем",
    searchMembersLabel: 'Пошук по імені чи електронній пошті',

    archiveEmployeeText: 'Архівувати працівника',
    archiveEmployeeModalApproving: 'Ви впевнені що хочете архівувати працівника',
    archiveEmployeeModalConsequences: 'Якщо Ви архівуєте його, він не матиме змоги користуватися можливостями команди.',
    archiveButton: 'Архівувати',
    unarchiveEmployeeText: 'Розархівувати працівника',
    unarchiveEmployeeModalApproving: 'Ви впевнені що хочете розархівувати працівника',
    unarchiveEmployeeModalConsequences:
      'Якщо Ви розархівуєте його, він матиме змоги користуватися можливостями команди.',
    unarchiveButton: 'Розархівувати працівника',
    employeeDeleteText: 'Видалити працівника',
    resendInvitationText: 'Перенадіслати запрошення',
    cancelInvitationText: 'Відмінити запрошення',
    cancelInvitationModalApproving: 'Ви впевнені що хочете відмінити запрошення до',
    cancelInvitationModalConsequences: 'Якщо Ви відміните його, то людина не матиме змоги приєднатися до команди.',
    nameLabel: "Ім'я",
    nameLabelClient: 'Назва',
    roleLabel: 'Роль',
    statusLabel: 'Статус',
    allMembersLabel: 'Всі Працівники',
    activeEmployeeStatus: 'Активні Працівники',
    pendingEmployeeStatus: 'В очікувані',
    archivedEmployeeStatus: 'Архівовано',

    activeMembersStatus: 'Активні',
    pendingMembersStatus: 'В очікувані',
    archivedMembersStatus: 'Архівовано',

    emptyPageSubtitle: 'Список виглядає порожнім, запросіть людей в команду',
    changeEmployeeRoleSuccess: 'Роль працівника успішно змінено',

    archiveEmployeeSuccess: 'Працівника успішно архівовано',
    unarchiveEmployeeSuccess: 'Працівника успішно розархівовано',
    actionFailed: 'Ви обрали не правильну дію',
    notFoundPageTitle: 'Ой.. Сторінку не знайдено!',
    notFoundPageDescription: 'Ми дуже старалися, але не змогли знайти сторінку на яку ви намагаєтеся перейти.',
    backToHome: 'На початкову сторіну',
    decisionMaker: 'Приймає рішення',
    latestUpdateTooltip: 'Останнє оновлення',
    clientTags: 'Теги клієнта',
    viewProfile: 'Переглянути профіль',
    searchByName: 'Пошук по імені',
    addDecisionMaker: 'Додати відповідального',
    editClient: 'Редагувати клієнта',
    editClientButton: 'Зберегти зміни',
    archiveClient: 'Архівувати клієнта',
    unarchiveClient: 'Розархівувати клієнта',
    openVacancy: 'Відкрита вакансія',
    createClientPageTitle: 'Створити клієнта',
    createClient: 'Створити клієнта',
    mySettings: 'Мої налаштування',
    changePassword: 'Змінити пароль',
    profilePictrue: 'Аватар профілю',
    uploadNewImage: 'Завантажити новий аватар',
    imageRestrictions: 'Всі .jpg, .png файли вагою до 10 МБ підтримуються',
    upload: 'Завантажити',
    deleteImage: 'Видалити аватар',
    latin: 'Латиниця',
    local: 'Місцевий',
    additionalInfo: 'Додаткова інформація',
    saveChanges: 'Зберегти зміни',
    clearAll: 'Очистити все',
    clickToUpload: 'Клікніть для завантаження',
    close: 'Закрити',
    save: 'Зберегти',
    deleteAccounts: 'Видалити аккаунт',
    areYouSure:
      'Ви впевнені що ви бажаєте видалити ваш аккаунт {{name}}?Якщо ви видалите його, всі дані будуть втрачені для вашого аккаунту.',
    changePasswordDescription:
      'Новий пароль не повинен збігатися зі старим і повинен містити великі та малі літери, спеціальні символи та цифри',
    imageIsTooLarge: 'Аватарка занадто велика.',
    notSuitableFormat: 'Вибраний формат не підтримується.',
    onlyLettersAreAllowed: 'Дозволяються лише літери, дефіси і пробіли',
    onlyLatinLettersAreAllowed: 'Дозволяються лише латинські літери, дефіси і пробіли',
    enterCorrectMobilePhone: 'Введіть правильний мобільний телефон',
    enterCorrectEmail: 'Введіть правильну адресу пошти',
    serverErrorText: 'Сервіс тимчасово недоступний!',
    serverErrorTextDescription:
      'Вибачте за незручності, але схоже, що є деякі технічні неполадки із сервером. Будь ласка спробуйте пізніше',
    serverErrorTextAuthor: '— Команда ІТ Підтримки',
    nameProfile: '{{name}} профіль',
    clientAvatar: 'Аватар клієнта',

    addNewSpecializationModalTitle: 'Додавання спеціалізвції',
    addNewSpecializationModalSubtitle:
      'Додайте нову спеціалізацію яку потім зможете використовувати при створенні нових кандидатів.',
    editSpecializationModalTitle: 'Редагування спеціалізації',
    editSpecializationModalSubtitle:
      'Відредагуйте спеціалізацію в один клік. Всі кандидати будуть оновленні згідно зі змінами.',
    deleteSpecializationModalTitle: 'Видалення спеціалізації',
    deleteSpecializationModalSubtitle:
      'Ви впевненні що хочете видалити спеціалізацію? Це призведе до змін в створених кандидатах.',
    specializationLabel: 'Спеціалізація',
    oldSpecializationLabel: 'Нова спеціалізація',
    newSpecializationLabel: 'Попередня спеціалізація',
    chooseSpecializationLabel: 'Оберіть спеціалізацію',
    nonUniqueSpecializationError: 'Цю спеціалізацію вже створено',
    emptySpecializationsError: 'Створіть принаймні одну спеціалізацію перед додаванням',
    addNewCandidateTagModalTitle: 'Теги кандидата',
    addNewCandidatetagModalSubtitle:
      'Додайте новий тег кандидата який потім зможете використовувати при створенні нових кандидатів.',
    editCandidateTagModalTitle: 'Редагування тегу кандидата',
    editCandidateTagModalSubtitle:
      'Відредагуйте тег кандидата в один клік. Всі кандидати будуть оновленні згідно зі змінами.',
    deleteCandidateTagModalTitle: 'Видалення тегу кандидата',
    deleteCandidateTagModalSubtitle:
      'Ви впевненні що хочете видалити тег кандидата? Це призведе до змін в створених кандидатах.',
    emptyTagsError: 'Створіть принаймні один тег перед додаванням',
    emptyChannelsError: 'Створіть принаймні один канал перед додаванням',
    nonUniqueTagError: 'Цей тег вже створено',
    nonUniqueChannelError: 'Цей канал вже створено',
    candidateTagLabel: 'Тег кандидата',
    forgotAddTagError: 'Забули додати тег? Додайте його або очистіть поле',
    tagsCreateSuccessMsg: 'Теги успішно створено',
    oldTagLabel: 'Попередній тег',
    newTagLabel: 'Новий тег',
    tagUpdateSuccessMsg: 'Тег успішно оновлено',
    tagDeleteSuccessMsg: 'Тег успішно видалено',
    chooseTagLabel: 'Оберіть тег',

    createCandidatePagTitle: 'Створення кандидата',
    mandatoryInformationTitle: 'Важлива інформація',
    cvAndLinksTitle: 'Резюме й посилання',
    nonUniqueLinkError: 'Це посилання вже додано',
    nonValidLinkError: 'Це посилання невалідне',
    moscovienLinkError: 'Посилання, що містять москальські(російські) домени, заборонені!',
    additionalInfoTitle: 'Додаткова інформація',
    clearForm: 'Очистити все',
    dropzoneTitle: 'Натисніть для завантаження або перетягніть в зазначену зону',
    dropzoneSubtitle: 'Підтримуються всі .pdf, .docx файли до 20 MB.',
    fileSizeError: 'Розмір документів завеликий',
    fileTypeError: 'Тип документу не підтримується',
    incorrectPhoneNumber: 'Неправильний мобільний номер',
    filtersModalTitle: 'Фільтри',
    specializationFilterLabel: 'По спеціалізації',
    gradeFilterLabel: 'По рівню',
    assignedToFilterLabel: 'Закріплені за',
    clientFilterLabel: 'По клієнту',
    vacancyFilterLabel: 'По вакансії',
    vacancyStatusFilterLabel: 'По статусу вакансії',
    searchCandidateLabel: 'Пошук по імені',
    candidateName: "Ім'я кандидата",
    notSuitableText: 'Не підходить',
    hireCandidateModalTitle: 'Наймання кандидата',
    hireCandidateModalSubtitle: 'Оберіть за якою вакансією найнято кандидата',
    rejectionCandidateModalTitle: 'Відмова кандидату',
    rejectionCandidateModalSubtitle: 'Опишіть причину по якій Ви відмовляєте кандидату',
    rejectTitleLabel: 'Заголовок відмови',
    rejectReasonLabel: 'Причина відмови',
    noDataToDisplayTitle: 'Упс.. Немає даних для відображення',
    noDataToDisplaySubtitle: 'Здається Ви ще не створили жодного кандидата, тож створіть першого прямо зараз!',
    noRemindersToDisplaySubtitle: 'Здається Ви ще не створили жодного ремайндера, тож створіть першого прямо зараз!',
    noClientsToDisplaySubtitle: 'Здається Ви ще не створили жодного клієнта, тож створіть першого прямо зараз!',
    noClientsVacanciesToDisplaySubtitle:
      'Здається Ви ще не додали клієнта до жодної вакансії, тож додайте до першої прямо зараз!',
    noCandidatesToDisplaySubtitle:
      'Здається у Вас немає жодного кандидата з цією вакансією, тож додайте її до декількох кандидатів прямо зараз!',
    candidatesActivitiesTab: 'Активності',
    candidatesVacanciesTab: 'Вакансії',
    candidatesCommentTitle: 'Заголовок',
    noAnyActivitiesSubtitle: 'Здається для кандидата не створено жодних активностей.',
    noAnyRemindersSubtitle: 'Здається для кандидата не створено жодних нагадувань.',

    addNewCommentActivityLabel: 'додав(-ла) новий коментар',
    updateCommentActivityLabel: 'змінив(-ла) коментар',
    deleteCommentActivityLabel: 'видалив(-ла) коментар',
    changeStatusActivityLabel: 'змінив(-ла) статус',
    addNewReminderActivityLabel: 'додав(-ла) нове нагадування',
    updateReminderActivityLabel: 'змінив(-ла) нагадування',
    deleteReminderActivityLabel: 'видалив(-ла) нагадування',

    editCandidateReminderText: 'Відредагувати нагадування',
    deleteCandidateReminderText: 'Видалити нагадування',
    deleteReminderModalSubtitle: 'Ви впевнені що хочете видалити це нагадування?',
    editReminderModalSubtitle: 'Змініть нагадування для цього кандидата',
    updateDate: 'Оновлення дати',
    createCandidateReminderSuccessMsg: 'Нагадування кандидата було успішно створено',
    updateCandidateReminderSuccessMsg: 'Нагадування кандидата було успішно оновлено',
    deleteCandidateReminderSuccessMsg: 'Нагадування кандидата було успішно видалено',
    profileText: 'Профіль',
    editCandidateText: 'Редагувати профіль',
    hireCandidateText: 'Найняти кандидата',
    rejectCandidateText: 'Не підходить',
    availableCandidateText: 'Доступний з',
    shortAvailableCandidateText: 'ДОСТУПНИЙ',
    unavailableCandidateText: 'Недоступний з',
    shortUnavailableCandidateText: 'НЕДОСТУПНИЙ',
    candidateCVTitle: 'Резюме кандидата',
    createCandidateCommentSuccessMsg: 'Коментар кандидата було успішно створено',
    updateCandidateCommentSuccessMsg: 'Коментар кандидата було успішно оновлено',
    deleteCandidateCommentSuccessMsg: 'Коментар кандидата було успішно видалено',
    editCandidateCommentText: 'Відредагувати коментар',
    deleteCandidateCommentText: 'Видалити коментар',
    deleteCommentModalSubtitle: 'Ви впевнені що хочете видалити цей коментар?',
    editCommentModalSubtitle: 'Змініть коментар для цього кандидата',
    noStatus: 'Без статусу',
    clientInformation: 'Клієнтська інформація',
    clientWebsite: 'Сайт клієнта',
    clientChannel: 'Канал клієнта',
    docsAndLinks: 'Документи та посилання',
    addNewClientTagModalTitle: ' Теги клієнта',
    addNewClientTagModalSubtitle:
      'Додайте новий тег клієнта який потім зможете використовувати при створенні нових клієнтів.',
    editClientTagModalTitle: 'Редагування тегу клієнта',
    editClientTagModalSubtitle: 'Відредагуйте тег клієнта в один клік. Всі клієнти будуть оновленні згідно зі змінами.',
    deleteClientTagModalTitle: 'Видалення тегу клієнта',
    deleteClientTagModalSubtitle:
      'Ви впевненні що хочете видалити тег клієнта? Це призведе до змін в створених клієнтах.',
    clientTagLabel: 'Тег клієнта',
    channelCreateSuccessMsg: 'Канал успішно створено',
    channelUpdateSuccessMsg: 'Канал успішно оновлено',
    channelDeleteSuccessMsg: 'Канал успішно видалено',
    nonAddedToListError: "Додайте в список натиснувши '+' в кінці поля",
    addNewClientChannelModalTitle: 'Канал клієнта',
    addNewClientChannelModalSubtitle:
      'Додайте новий канал клієнта який потім зможете використовувати при створенні нових клієнтів.',
    editClientChannelModalTitle: 'Редагування каналу клієнта',
    editClientChannelModalSubtitle:
      'Відредагуйте канал клієнта в один клік. Всі клієнти будуть оновленні згідно зі змінами.',
    deleteClientChannelModalTitle: 'Видалення каналу клієнта',
    deleteClientChannelModalSubtitle:
      'Ви впевненні що хочете видалити канал клієнта? Це призведе до змін в створених клієнтах.',
    clientChannelLabel: 'Канал клієнта',
    oldChannelLabel: 'Попередній канал',
    newChannelLabel: 'Новий канал',
    editChannelSuccesMsg: 'Канал змінений успішно',
    chooseChannelLabel: 'Оберіть канал',
    createClientSuccessMsg: 'Клієнта успішно створено',
    updateClientSuccessMsg: 'Клієнта успішно оновлено',
    availabilityLabel: 'Доступність',
    changeClientStatusSuccessNotification: 'Статус клієнта успішно змінено',
    searchByNameLabel: 'Пошук по імені',
    rejectCandidateSuccessMsg: 'Кандидата успішно відхилено',
    hireCandidateSuccessMsg: 'Кандидата успішно найнято',
    forVacancyText: 'на цю вакансію',
    nonValidDate: 'Введено некорректну дату',
    nonValidMinDate: 'Оберіть дату від сьогоднішнього дня',
    nonValidMaxDate: 'Оберіть дату до сьогоднішнього дня',
    createCandidateTagTitle: 'Створення кандидата',
    reminderTitle: 'Нагадування',
    notificationTitle: 'Сповіщення',
    viewReminderLinkText: 'Перейти до нагадування',
    markAllAsReadText: 'Позначити всі як прочитані',
    noRemindersSubtitle: 'У вас немає нагадувань на даний момент. Перевірте пізніше.',
    selectVacancyModalTitle: 'Оберіть вакансію',
    selectVacancyModalSubtitle:
      'Щоб змінити статус кандидата, потрібно спочатку обрати вакансію в фільтрах, після цього можна буде змінити статус кандидата.',
    vacancyStatusModalTitle: 'Вакансія замкнена',
    vacancyStatusModalSubtitle: 'Ви не можете змінити статус кандидата, якщо вакансію замкнено чи відмінено',
    searchVacancyLabel: 'Пошук по назві',
    createVacancyButton: 'Створити вакансію',
    createTemplateButton: 'Створити шаблон',
    allVacanciesLabel: 'Всі вакансії',
    vacancyTableHeaderLabel: 'Вакансія',
    clientLabel: 'Клієнт',
    vacancyActiveDays: 'Днів активності',
    endDateLabel: 'Кінцева дата',
    noVacanciesToDisplaySubtitle: 'Здається Ви ще не створили жодної вакансії, тож створіть першу прямо зараз!',
    publishVacancyText: 'Опублікувати вакансію',
    publishVacancyTextSubtitle:
      'Ви впевнені, що хочете опублікувати цю вакансію? Вона буде зарахована до загальної статистики і не може бути видалена.',
    publishVacancyButton: 'Опублікувати',

    deleteVacancyText: 'Видалити вакансію',
    deleteVacancyTextSubtitle:
      'Ви впевнені, що хочете видалити цю вакансію? Вся інформація про цю вакансію буде втрачена.',
    deleteVacancyButton: 'Видалити вакансію',
    closeVacancySuccessfulyText: 'Закрити успішно',
    closeVacancyUnsuccessfulyText: 'Закрити неуспішно',
    copyVacancyText: 'Скопіювати вакансію',
    copyVacancyTextSubtitle:
      'Ви впевнені, що хочете скопіювати цю вакансію? Копію вакансії буде переведено у статус "В редагуванні" і всі прив\'язані до початкової вакансії кандидати будуть скопійовані до нової вакансії',
    copyVacancyButton: 'Скопіювати',
    cancelVacancyText: 'Відмінити вакансію',
    closeVacancyButton: 'Закрити вакансію',
    cancelVacancySubText:
      'Ви впевнені, що хочете скасувати цю вакансію? Ця вакансія буде переведена в статус анульованої, вакансія не буде врахована в статистиці.',
    publishVacancySuccess: 'Вакансію успішно опубліковано',
    closeVacancySuccessfulySuccess: 'Вакансію успішно закрито',
    closeVacancyUnsuccessfulySuccess: 'Вакансію неуспішно закрито',
    cancelVacancySuccess: 'Вакансію успішно відмінено',
    copyVacancySuccess: 'Вакансію успішно скопійовано',
    vacancyInformationTitle: 'Інформація про вакансію',
    vacancyNameField: 'Назва вакансії',
    templateField: 'Шаблон',
    clientField: 'Назва клієнта',
    chooseGradeField: 'Оберіть рівень',
    assignedToField: 'Назначено на',
    specializationField: 'Спеціалізація',
    aboutVacancyField: 'Про вакансію',
    createAsTemplateLabel: 'Створити як шаблон',
    editVacancySuccessMsg: 'Вакансію успішно змінено',
    createVacancySuccessMsg: 'Вакансію успішно створено',
    editVacancyButton: 'Зберегти зміни',
    detailsTabText: 'Деталі',
    decisionMakersTabText: 'Агенти',
    vacanciesTabText: 'Вакансії',
    candidatesTabText: 'Кандидати',
    hiredCandidateLabel: 'Найнятий кандидат',
    availabilityFilterLabel: 'По доступності',
    availableLabel: 'Так',
    unavailableLabel: 'Ні',
    createdBy: 'Хто створив',
    create: 'Створити',
    remindersDate: 'Дата нагадування',

    incorrectName: 'Некоректна назва',
    tooLongName: 'Подана назва задовга (макс. 80 символів)',
    incorrectLink: 'Некоректне посилання',
    incorrectCity: 'Некоректна назва міста',
    acceptInviteSuccess: 'Запрошення успішно прийнято',
    createTeamTooltipText: 'Створити команду',

    addDicisionMakerTitle: 'Запросіть особу, яка приймає рішення, до вашого клієнта',
    addDicisionMakerSubtitle:
      'Ви можете додати до клієнта кількох осіб, які приймають рішення, і вони матимуть доступ до вакансій та кандидатів.',
    addDicisionMakerButton: 'Надіслати запрошення',

    dicisionMakerNameTitle: "Ім'я",
    dicisionMakerEmailTitle: 'Пошта',
    dicisionMakerDateTitle: 'Дата',

    dicisionMakerReSendInvitation: 'Надіслати запрошення повторно',
    dicisionMakerCancelInvite: 'Відмінити запрошення',
    dicisionMakerArchiveWorker: 'Архівувати працівника',
    dicisionMakerUnArchiveWorker: 'Розархівувати працівника',

    dicisionMakerEmail: 'Пошта',
    dicisionMakerData: 'Дата',
  },
};
